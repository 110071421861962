import { getImage } from "gatsby-plugin-image"

function findImage(images: any, img: any) {
  let image
  if (img) {
    const imageIndex = images.edges.findIndex(
      x => x.node.name === img.split(".").slice(0, -1).join(".")
    )

    image = getImage(images.edges[imageIndex].node.childImageSharp)
  }
  return image
}

export default findImage
